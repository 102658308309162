exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-of-integrity-jsx": () => import("./../../../src/pages/code-of-integrity.jsx" /* webpackChunkName: "component---src-pages-code-of-integrity-jsx" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-our-policy-on-use-of-ai-jsx": () => import("./../../../src/pages/our-policy-on-use-of-AI.jsx" /* webpackChunkName: "component---src-pages-our-policy-on-use-of-ai-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-refund-policy-jsx": () => import("./../../../src/pages/refund-policy.jsx" /* webpackChunkName: "component---src-pages-refund-policy-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

